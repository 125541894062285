import React, {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import { reducer } from "./reducer";
import { useNavigate, useParams } from "react-router-dom";
import client, { socket } from "../services/feathers";
import locations from "../services/locations";
import moment from "moment/moment";
import { v4 as uuidv4 } from "uuid";

const AppContext = createContext();

const initialState = {
  user:
    typeof window !== "undefined"
      ? localStorage.getItem("currentUser")
        ? JSON.parse(localStorage.getItem("currentUser"))
        : null
      : null,
  customer:
    typeof window !== "undefined"
      ? localStorage.getItem("currentCustomer")
        ? JSON.parse(localStorage.getItem("currentCustomer"))
        : null
      : null,
  consumeType: null,
  uuid: null,
  address: null,
  orders: [],
  branch: null,
  closed: false,
  branches: [],
  categories: [],
  deliveryInfo: null,
  products: [],
  complementGroups: [],
  complements: [],
  offers: [],
  coupons: [],
  cart: [],
  loaded: false,
};

/* typeof window !== "undefined"
      ? localStorage.getItem("userCart")
        ? JSON.parse(localStorage.getItem("userCart"))
        : []
      : [], */

export const AppProvider = ({ children }) => {
  //DISPONIBILIZO O PROVIDER EM TODO O APP (STATE E DISPATCH)
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef(state);

  const navigate = useNavigate();

  const { restaurantName } = useParams();

  const getData = async (location, payload) => {
    let response = await client.service(location).find({ query: payload });
    return response;
  };

  const getSingleData = async (location, payload) => {
    let response = await client.service(location).get(payload);
    return response;
  };

  const postData = async (location, payload) => {
    let response = await client.service(location).create(payload);
    return response;
  };

  const patchData = async (location, id, payload) => {
    ////console.log("trying to patch");

    ////console.log(payload);

    let response = await client.service(`${location}`).patch(id, payload);
    return response;
  };

  const removeData = async (location, id) => {
    let response = await client.service(location).remove(id);
    return response;
  };

  const listenStatus = () => {
    const listen = socket;

    listen.on("manager/companies created", (res) => {
      ////console.log("resultado do on");
      ////console.log(res);
    });

    listen.on("manager/products patched", (res) => {
      if (res.branch === state.branch._id) {
        ////console.log("products patched");
        const currentProducts = state.products.filter((p) => p._id !== res._id);

        /*         //console.log("state from listen", state);

        //console.log("currentProducts in state with no filter", state.products);
        //console.log("patched product", res); */

        dispatch({ type: "SET_PRODUCTS", payload: [...currentProducts, res] });
      }
    });

    listen.on("manager/orders patched", (res) => {
      if (res.branch === state.branch._id) {
        ////console.log("orders patched");

        //setLastOrder(res);
        //CREATING A NEW ARRAY INSTEAD OF USING THE ORIGINAL STATE
        /* const previousOrders = [
          ...state.orders.filter((o) => o._id !== res._id),
        ]; */
        ////CHECAR LÁ PELO ORDERS E APENAS COMPARAR O ID DO LAST_ORDER OU REDIRECIONAR MESMO

        //const finalPayload = [res, ...previousOrders];

        /* //console.log("checando payload");
        //console.log("all orders without filter", state.orders);
        //console.log("previousOrders", previousOrders);
        //console.log("patched order", res);
        //console.log("final payload", finalPayload);
        //console.log("checando payload"); */

        const finalPayload = [
          res,
          ...state.orders.filter((o) => o._id !== res._id),
        ];
        dispatch({ type: "SET_ORDERS", payload: finalPayload });
      }
    });

    listen.on("manager/branches patched", (res) => {
      ////console.log("branches patched");
      //const currentProducts = state.products.filter((p) => p._id !== res._id);

      ////console.log(res);

      ////console.log("state branch", state.branch);

      if (state.branch && state.branch._id === res._id) {
        ////console.log("current branch patched");
        ////console.log(res);
        dispatch({ type: "SET_BRANCH", payload: res });
      }

      //dispatch({ type: "SET_PRODUCTS", payload: [...currentProducts, res] });
    });

    listen.on("manager/customers patched", (res) => {
      ////console.log("customers patched");
      if (state.customer && res._id === state.customer._id) {
        dispatch({ type: "SET_CUSTOMER", payload: res });
      }
    });

    listen.on("manager/complementGroups patched", (res) => {
      ////console.log("complementGroups patched");
      const currentcomplementGroups = state.complementGroups.filter(
        (p) => p._id !== res._id
      );

      dispatch({
        type: "SET_COMPLEMENTGROUPS",
        payload: [...currentcomplementGroups, res],
      });
    });
  };

  const registerSessionActivity = async (action, branch = null) => {
    //const response = await patchData(locations.sessions, session, actions);

    const currentState = stateRef.current;

    //console.log("currentState", currentState);
    //console.log("calling registerSessionActivity");

    const date = new Date();

    let actionPayload = { ...action, createdAt: date.toISOString() };

    if (state.customer && state.uuid && branch) {
      let cartObj = {
        uuid: state.uuid,
        customerId: state.customer._id,
        customer: state.customer,
        modality: state.consumeType,
        companyId: branch.company,
        branchId: branch._id,
        actions: [],
      };

      if (state.consumeType === 1) {
        //DELIVERY ADDRESS
        cartObj.address = state.address;
        cartObj.deliveryInfo = state.deliveryInfo;
      }

      ////console.log("cartObj", cartObj);

      getData(locations.sessions, {
        uuid: state.uuid,
      }).then((c) => {
        ////console.log("res get", c);
        if (c.data.length === 0) {
          postData(locations.sessions, cartObj).then((res) => {
            console.log("res new session", res);
          });
        } else {
          console.log("patch session");
          const actions = c.data[0].actions;

          if (state.modality === 1) {
            patchData(locations.sessions, c.data[0]._id, {
              address: state.address,
              deliveryInfo: state.deliveryInfo,
              modality: state.consumeType,
              companyId: branch.company,
              branchId: branch._id,
              actions: action ? [...actions, actionPayload] : actions,
            })
              .then((res) => {
                ////console.log("res patch", res);
              })
              .catch((e) => {
                ////console.log(e);
              });
          } else {
            patchData(locations.sessions, c.data[0]._id, {
              modality: state.consumeType,
              companyId: branch.company,
              branchId: branch._id,
              actions: action ? [...actions, actionPayload] : actions,
            })
              .then((res) => {
                ////console.log("res patch", res);
              })
              .catch((e) => {
                ////console.log(e);
              });
          }
        }
      });
      /* postData(locations.carts, cartObj).then((res) => {
          //console.log("res cart", res);
        }); */
    } else {
      console.log("no fields");
      console.log("state.customer", state.customer);
      console.log("state.uuid", state.uuid);
      console.log("currentState.branch", currentState.branch);
      //console.log(state);
    }
    ////console.log("cartObj", cartObj);
  };

  /* useEffect(() => {
    registerSessionActivity();
  }, []); */

  const applyCoupon = (coupon) => {
    dispatch({ type: "SET_APPLIEDCOUPON", payload: coupon });

    /* if (coupon && coupon.reward.type === 2) {
      //FREE DELIVERY

      //console.log("free delivery");

      const newInfo = {
        deliveryTime: state.deliveryInfo.deliveryTime,
        price: 0,
      };
      setDeliveryInfo(newInfo);
    } */
  };

  const calculateOfferPrice = (product, offer) => {
    //CALCULAR O PREÇO DE PRODUTO EM OFERTA
    //JA NO MAP DAS CATEGORIAS, SE ELA FOR CATEGORIA, CALCULAR O OFFERPRICE DE CADA UM LA NA LISTA
    //MAS É NECESSÁRIO UM DISPATCH

    let offerPrice = 0;
    let newProduct = product;

    const productPrice = newProduct.price;

    if (offer.reward && offer.reward.type === 0) {
      //VALOR FIXO
      offerPrice = productPrice - offer.reward.amount;
    }

    if (offer.reward && offer.reward.type === 1) {
      //PORCENTAGEM
      offerPrice = productPrice * (1 - offer.reward.amount / 100);
    }

    return offerPrice;
  };

  const applyOffers = (products, categories, offers) => {
    let appliedProducts = [];

    let appliedCategories = [];

    //HANDLE COUPONS
    const filteredCoupons = offers.filter((o) => o.rules.isCoupon);
    dispatch({ type: "SET_COUPONS", payload: filteredCoupons });

    //HANDLE PRODUCTS AND CATEGORIES
    offers.map((offer) => {
      if (offer.matches.type === 0) {
        //APPLIES TO PPRODUCTS
        const matchProducts = products
          .filter((p) => offer.matches.items.includes(p._id))
          .map((product) => {
            return {
              ...product,
              offer: offer,
              offerPrice: calculateOfferPrice(product, offer),
            };
          });
        if (matchProducts.length) {
          appliedProducts.push(matchProducts[0]);
          //console.log("matched products", matchProducts);
        }
      }

      if (offer.matches.type === 1) {
        //APPLIES TO CATEGORIES
        const matchCategories = categories
          .filter((c) => offer.matches.items.includes(c._id))
          .map((category) => {
            return {
              ...category,
              offer: offer,
            };
          });
        appliedCategories = matchCategories;
        //console.log("matched categories", matchCategories);
      }
    });

    //FILTER PRODUCTS AND CATEGORIES WHO ARE NOT OFFER - THEN PUSH THE APPLIED ALONG WITH THEM TO A FINAL ARRAY
    const filteredProducts = products.filter(
      (item) => !appliedProducts.map((obj) => obj._id).includes(item._id)
    );

    const filteredCategories = categories.filter(
      (item) => !appliedCategories.map((obj) => obj._id).includes(item._id)
    );

    const finalProducts = [...appliedProducts, ...filteredProducts];
    const finalCategories = [...appliedCategories, ...filteredCategories];

    let appliedFromCategory = [];

    const categoryProducts = finalCategories.flatMap((c) => {
      ////console.log("category map", c);
      return finalProducts
        .filter((p) => c.items.includes(p._id))
        .map((fp) => {
          const prod = c.offer
            ? {
                ...fp,
                offer: c.offer,
                offerPrice: calculateOfferPrice(fp, c.offer),
              }
            : fp;
          return prod;
        });
    });

    /* //console.log(
      "applied finalProducts products",
      finalProducts.filter((p) => p.offer)
    ); */

    //DISPATCH UPDATED PRODUCTS AND CATEGORIES

    //USE REF HERE

    stateRef.current.products = categoryProducts;
    stateRef.current.categories = finalCategories;

    dispatch({ type: "SET_PRODUCTS", payload: categoryProducts });
    dispatch({ type: "SET_CATEGORIES", payload: finalCategories });

    ////console.log("finalProducts", finalProducts);
  };

  const setUUID = (id) => {
    dispatch({ type: "SET_UUID", payload: id });
  };

  const initialProductPriceUpdate = () => {
    const currentStateRef = stateRef.current;

    if (currentStateRef.products && currentStateRef.products.length) {
      //console.log("state.products INTERVAL", currentStateRef.products);
      //console.log("running product loop");
      const products = handleDynamicPrices(currentStateRef.products);

      //console.log("products inside interval", products);

      const normalProducts = currentStateRef.products.filter(
        (p) => !products.some((p2) => p2._id === p._id)
      );

      //console.log("normalProducts", normalProducts);

      const updatedProducts = [...normalProducts, ...products];

      //console.log("updatedProducts", updatedProducts);

      dispatch({ type: "SET_PRODUCTS", payload: updatedProducts });
    }
  };

  const startProductLoop = () => {
    //TRY USING REF TO CATCH THE STATE

    const currentStateRef = stateRef.current;

    //console.log("calling the loop", currentStateRef);

    setInterval(() => {
      //console.log("running the loop", currentStateRef);
      //console.log("currentStateRef.products", currentStateRef.products);

      if (currentStateRef.products && currentStateRef.products.length) {
        //console.log("state.products INTERVAL", currentStateRef.products);
        //console.log("running product loop");
        const products = handleDynamicPrices(currentStateRef.products);

        //console.log("products inside interval", products);

        const normalProducts = currentStateRef.products.filter(
          (p) => !products.some((p2) => p2._id === p._id)
        );

        //console.log("normalProducts", normalProducts);

        const updatedProducts = [...normalProducts, ...products];

        //console.log("updatedProducts", updatedProducts);

        dispatch({ type: "SET_PRODUCTS", payload: updatedProducts });
      } else {
        console.log("nothing found for current products ref");
      }
    }, 1 * 30 * 1000);
  };

  const populateCompany = async (restaurantName) => {
    ////console.log("setando company", //console.log(restaurantName));
    getCompany(restaurantName).then((c) => {
      ////console.log("the company is set", c);

      if (!state.uuid) {
        const uuid = uuidv4();
        setUUID(uuid);
        //console.log("set uuid from populateCompany");
      }

      if (c.length) {
        dispatch({ type: "SET_COMPANY", payload: c[0] });
        getBranches(c[0]._id).then((bs) => {
          ////console.log("found branches", bs);
          dispatch({ type: "SET_BRANCHES", payload: bs });
        });
      }
    });
  };

  const returnMatchingDynamicPrice = (p) => {};

  const handleDynamicPrices = (p) => {
    //CHECK THE FUNCTION INSIDE INTERVAL

    const dayOfWeek = moment().day();

    const newProducts = p
      .filter((prod) => prod.dynamicPriceEnabled)
      .map((product) => {
        const period = checkCurrentProductPeriods(
          dayOfWeek.toString(),
          product
        );

        //console.log("periodo inside loop", period);

        if (period) {
          console.log("product inside", product);
          //console.log("inside products periodo", period);
          return { ...product, price: parseFloat(period.price) };
        } else {
          return product;
        }
      });

    /* console.log(
      "newProducts",
      newProducts.filter((prod) => prod._id === "6664b86fa31301f8b40fe265")
    ); */

    return newProducts;
  };

  const populateData = async (branch) => {
    /* CHAIN FUNCTIONS TO MAKE SURE THEY ARE LOADED IN THE CORRECT ORDER */
    getBranch(branch).then((b) => {
      if (b.length !== 0) {
        const branchId = b[0]._id;
        dispatch({ type: "SET_BRANCH", payload: b[0] });
        getProductCategories(branchId).then((c) => {
          //SET CATEGORIES
          dispatch({ type: "SET_CATEGORIES", payload: c });
          getProducts(branchId).then(async (p) => {
            //SET PRODUCTS
            //handleProductsDynamicPrices before dispatch
            dispatch({ type: "SET_PRODUCTS", payload: p });
            /* setTimeout(() => {
              startProductLoop();
            }, 500); */
            getComplementGroups(branchId).then((cg) => {
              //SET COMPLEMENT GROUPS
              dispatch({ type: "SET_COMPLEMENTGROUPS", payload: cg });
              getComplements(branchId).then((comp) => {
                //SET COMPLEMENTS
                dispatch({ type: "SET_COMPLEMENTS", payload: comp });
                ////console.log("all main products and their complements loaded");
                getOffers(branchId).then((offers) => {
                  ////console.log("offers", offers);
                  dispatch({ type: "SET_OFFERS", payload: offers });

                  applyOffers(p, c, offers);

                  if (state.customer) {
                    getCustomerOrders(branchId).then((o) => {
                      ////console.log("customer orders when app loads", o);
                      dispatch({ type: "SET_ORDERS", payload: o });
                    });
                  } else {
                    dispatch({ type: "SET_ORDERS", payload: [] });
                  }
                  //CALL THE INITIAL HANDLE PRODUCTS
                  initialProductPriceUpdate();
                  dispatch({ type: "SET_LOADED", payload: true });
                });
              });
            });
          });
        });
      }
    });
  };

  useEffect(() => {
    if (state.company) {
      /* //console.log(
        "check if the stored customer belongs to the current company"
      );
      //console.log(state.company); */

      if (state.customer && state.customer.customerOf === state.company._id) {
        //console.log("valid company customer");
      } else {
        //DISPATCH AN EMPTY USER, BUT DON'T CLEAR THE STORED VALUE
        dispatch({ type: "SET_CUSTOMER", payload: null });
      }
    }
  }, [state.company]);

  /* useEffect(() => {
    //console.log("products updated");
  }, [state.products]); */

  /* useEffect(() => {
    //console.log("categories updated");
    ////console.log(state.categories);
  }, [state.categories]); */

  /*   useEffect(() => {
    if (state.branch) {
      //console.log("state.cart", state.cart);

      const cart = state.cart;

      const filteredCart = cart.filter(
        (p) => p.branch === state.branch && state.branch._id.toString()
      );

      //console.log("filteredCart", filteredCart);
      //console.log("currentBranch", state.branch._id);

      //updateCart(filteredCart);
    }
  }, [state.branch]); */

  const authUser = () => {
    const payload = {
      strategy: "local",
      email: "admin@wedo.com.br",
      password: "teste123",
    };

    client
      .authenticate(payload)
      .then((res) => {
        ////console.log("login response");
        ////console.log(res);

        setUser(res);
        //AUTENTICADO - CHAMAR ===TEMPORARIAMENTE=== OS PRODUTOS AQUI
        //populateData();
      })
      .catch((e) => {
        //console.log("erro no login");
        ////console.log(e);
      });
  };

  const createCompany = async () => {
    const payload = {
      name: "Teste WebSocket",
    };

    await client.service("manager/companies").create(payload);
  };

  /* INIT DATA POPULATING FUNCTIONS */
  /* Functions that populate initial menu state */

  const getCompany = async (slug) => {
    const response = await getData("manager/companies", {
      slug: slug,
    });
    return response.data;
  };

  const getBranch = async (slug) => {
    const response = await getData("manager/branches", {
      _id: slug,
    });
    return response.data;
  };

  const getBranches = async (id) => {
    const response = await getData("manager/branches", {
      company: id,
    });
    return response.data;
  };

  const setDeliveryInfo = (info) => {
    dispatch({ type: "SET_DELIVERYINFO", payload: info });
  };

  const getProductCategories = async (branch) => {
    const response = await getData("manager/product-categories", {
      branch: branch,
    });
    return response.data;
  };

  const setConsumeType = (type) => {
    dispatch({ type: "SET_CONSUMETYPE", payload: type });
  };

  const setAddress = (type) => {
    dispatch({ type: "SET_ADDRESS", payload: type });
  };

  const getProducts = async (branch) => {
    const response = await getData("manager/products", {
      branch: branch,
      active: true,
      $limit: 1000,
    });
    return response.data;
  };
  const getComplementGroups = async (branch) => {
    const response = await getData("manager/complementGroups", {
      branch: branch,
      $limit: 1000,
    });
    return response.data;
  };
  const getComplements = async (branch) => {
    const response = await getData("manager/complements", {
      branch: branch,
      $limit: 1000,
    });
    return response.data;
  };
  const getOffers = async (branch) => {
    const response = await getData("manager/offers", {
      branch: branch,
      active: true,
    });
    return response.data;
  };

  const getCustomerOrders = async (branch) => {
    const response = await getData("manager/orders", {
      branch: branch,
      "customer._id": state.customer && state.customer._id,
      $sort: {
        createdAt: -1,
      },
    });
    return response.data;
  };

  /* END DATA POPULATING FUNCTIONS */

  const closeStatus = (action) => {
    ////console.log("setando status da loja", action);
    dispatch({ type: "SET_CLOSED", payload: action });
  };

  const checkCurrentTimeInPeriods = (day, workingHours) => {
    ////console.log("workingHours", workingHours);

    const currentDay = workingHours[day];
    const currentTime = moment().format("HH:mm");

    const isInPeriod = currentDay.periods.some((period) => {
      const fromTime = moment(period.from, "HH:mm");
      const toTime = moment(period.to, "HH:mm");

      return moment().isBetween(fromTime, toTime);
    });

    if (isInPeriod) {
      //ABRIR LOJA -
      ////console.log(`User is currently within a period on ${currentDay.label}`);
      closeStatus(false);
    } else {
      //FECHAR LOJA
      ////console.log(`User is not within any period on ${currentDay.label}`);
      closeStatus(true);
    }
  };

  const checkCurrentProductPeriods = (day, product) => {
    ////console.log("workingHours", workingHours);

    const currentDay =
      product.dynamicPricesTable && product.dynamicPricesTable[day];
    const currentTime = moment().format("HH:mm");

    let foundPeriod;

    currentDay.periods
      .filter((per) => per.from !== "" && per.to !== "" && per.price !== "")
      .some((period) => {
        const fromTime = moment(period.from, "HH:mm");
        const toTime = moment(period.to, "HH:mm");

        if (moment().isBetween(fromTime, toTime)) {
          //console.log("period", period);
          foundPeriod = period;
        } else {
          foundPeriod = null;
        }
      });

    return foundPeriod;
  };

  useEffect(() => {
    if (state.branch !== null) {
      //MAKE AN INITIAL CHECK BEFORE THE TIMER
      const dayOfWeek = moment().day();

      checkCurrentTimeInPeriods(
        dayOfWeek.toString(),
        state.branch.workingHours
      );

      ////console.log("disparando timer");
      const checkPeriodsInterval = setInterval(() => {
        checkCurrentTimeInPeriods(
          dayOfWeek.toString(),
          state.branch.workingHours
        );
      }, 1 * 120 * 1000);
    }
  }, [state.branch]);

  useEffect(() => {
    //SETTING STATE TO REF TO CALL IT INSIDE OTHER FUNCTIONS
    //console.log("state changed");
    stateRef.current.products = state.products;
    stateRef.current.categories = state.categories;
    stateRef.current.branch = state.branch;
    stateRef.current.branches = state.branches;
    stateRef.current.offers = state.offers;
    stateRef.current.uuid = state.uuid;
  }, [
    state.products,
    state.categories,
    state.branch,
    state.branches,
    state.offers,
    state.uuid,
  ]);

  useEffect(() => {
    //console.log("app loaded");
    //authUser();
    //UPDATE CUSTOMER
    //getCustomer()
    if (state.customer !== null && state.customer.name !== "") {
      ////console.log("existe customer em local storage");
      getCustomer(state.customer._id).then((c) => {
        ////console.log("response from getCustomer", c);
        setCustomer(c);
      });
    }
    //UPDATE CUSTOMER
    //createCompany();
  }, []);

  let listening = false;

  useEffect(() => {
    if (!listening && state.branch !== null) {
      listenStatus();
      listening = true;
      //console.log("now listening");
    }
  }, [state.orders]);

  function setUser(user) {
    dispatch({ type: "SET_USER", payload: user });
  }

  function setCustomer(customer) {
    if (customer.name !== "" && customer._id) {
      dispatch({ type: "SET_CUSTOMER", payload: customer });
    }
  }

  function setLastOrder(order) {
    dispatch({ type: "SET_LASTORDER", payload: order });
    //PUSH THE LAST ORDER TO THE CURRENT ORDERS AS WELL
    dispatch({ type: "SET_ORDERS", payload: [order, ...state.orders] });
  }

  const getCustomer = async (id) => {
    let response = await getSingleData(locations.customers, id);
    return response;
  };

  useEffect(() => {
    ////console.log("customer alterado");
    ////console.log(state.customer);
    if (state.branch) {
      getCustomerOrders(state.branch._id).then((o) => {
        ////console.log("customer orders when customer changes", o);
      });
    }
  }, [state.customer]);

  useEffect(() => {
    ////console.log("branch has been set");
    ////console.log("set branch", state.branch);
  }, [state.branch]);

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    dispatch({ type: "SET_USER", payload: null });
  }

  function isLogged() {
    if (
      state.user !== null ||
      (typeof window !== "undefined" &&
        localStorage.getItem("token") &&
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== null)
    ) {
      return true;
    }
  }

  function getToken() {
    if (typeof window !== "undefined" && localStorage.getItem("token")) {
      return localStorage.getItem("token");
    }
  }

  function addToCart(item) {
    dispatch({ type: "SET_CART", payload: [...state.cart, item] });
  }

  function removeFromCart(item) {}

  function updateCart(item) {
    dispatch({ type: "SET_CART", payload: item });
  }

  useEffect(() => {
    if (state.branch && state.cart.length && state.uuid) {
      let cartObj = {
        uuid: state.uuid,
        customerId: state.customer._id,
        customer: state.customer,
        items: state.cart,
        modality: state.consumeType,
        company: state.branch.company,
        branch: state.branch._id,
        status: 0,
        finished: false,
      };

      if (state.consumeType === 1) {
        //DELIVERY ADDRESS
        cartObj.address = state.address;
        cartObj.deliveryInfo = state.deliveryInfo;
      }

      ////console.log("cartObj", cartObj);

      if (state.cart.length) {
        ////console.log("save cart");

        getData(locations.carts, {
          uuid: state.uuid,
        }).then((c) => {
          ////console.log("res get", c);
          if (c.data.length === 0) {
            postData(locations.carts, cartObj).then((res) => {
              ////console.log("res cart", res);
            });
          } else {
            if (state.modality === 1) {
              patchData(locations.carts, c.data[0]._id, {
                address: state.address,
                deliveryInfo: state.deliveryInfo,
                items: state.cart,
                modality: state.consumeType,
                branch: state.branch._id,
              })
                .then((res) => {
                  ////console.log("res patch", res);
                })
                .catch((e) => {
                  ////console.log(e);
                });
            } else {
              patchData(locations.carts, c.data[0]._id, {
                items: state.cart,
                modality: state.consumeType,
                branch: state.branch._id,
              })
                .then((res) => {
                  ////console.log("res patch", res);
                })
                .catch((e) => {
                  ////console.log(e);
                });
            }
          }
        });
        /* postData(locations.carts, cartObj).then((res) => {
          //console.log("res cart", res);
        }); */
      }
      ////console.log("cartObj", cartObj);
    } else {
      ////console.log("uuid", state.uuid);
    }
  }, [state.cart]);

  useEffect(() => {
    if (state.user !== null) {
      localStorage.setItem("currentUser", JSON.stringify(state.user));
      /* patchData(locations.customers, state.user._id, {
        addresses: state.user.addresses
    }); */
    } else {
      ////console.log("user not found");
    }
  }, [state.user]);

  useEffect(() => {
    if (state.cart !== null) {
      localStorage.setItem("userCart", JSON.stringify(state.cart));
    }
  }, [state.cart]);

  useEffect(() => {
    //FAZER O PATCH AQUI?
    localStorage.setItem("currentCustomer", JSON.stringify(state.customer));

    ////console.log("customer changed", state.customer);

    /* if (state.customer) {
      patchData(locations.customers, state.customer._id, {
        ...state.customer,
      }).then((res) => {
        //console.log("customer updated", res);
      });
    } */
  }, [state.customer]);

  return (
    <AppContext.Provider
      value={{
        state,
        getData,
        getSingleData,
        applyCoupon,
        postData,
        patchData,
        removeData,
        registerSessionActivity,
        setUser,
        setUUID,
        setLastOrder,
        setCustomer,
        addToCart,
        updateCart,
        logout,
        isLogged,
        startProductLoop,
        getToken,
        populateCompany,
        setConsumeType,
        setAddress,
        populateData,
        setDeliveryInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useApp() {
  //USO/LEIO OS DADOS EM USEAPP()
  const context = useContext(AppContext);
  return context;
}
